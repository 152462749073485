import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';

import { environment } from '../environments/environment';
import { SystemService } from './system/system.service';
import 'rxjs/add/operator/map';

import * as Parse from 'parse';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'web';
  isNavbarCollapsed = true;

  currenTs = null;
  timestamp = null;

  public ngOnInit(): void {
    // throw 'error';
    if (!isPlatformBrowser(this.platformId)) {
      const bases = this.document.getElementsByTagName('base');

      if (bases.length > 0) {
        bases[0].setAttribute('href', environment.baseHref);
      }
    }
    this.timestamp = this.getTs();
  }


  constructor(@Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) private document: any, private http: Http,
    private route: ActivatedRoute,
    public router: Router,
    public system: SystemService) {

    if (localStorage.getItem('user')) {
      const user_id = localStorage.getItem('user');
      localStorage.setItem('user', user_id.toString());
      this.system.IS_LOGIN = true;
      Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
      (Parse as any).serverURL = environment.serverURL;

      const users = Parse.Object.extend('User');
      const query = new Parse.Query(users);

      query.equalTo('ID', localStorage.getItem('user'));
      query.find().then((results) => {
        if (results.length > 0) {
          this.system.USER = results[0];
        }
      });
    }

    this.route.queryParams.subscribe(params => {
      if (typeof params.test !== 'undefined') {
        if (params.test === 'auth' && environment.production === false) {
          const headers = new Headers();
          headers.append('Content-Type', 'application/json; charset=utf-8');
          headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
          headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

          this.http.post(this.system.generateRequestUrl('/functions/UserSubscriptionUpdate', null, environment.serverURL),
            JSON.stringify({
              user_id: '1GMHG40Z1o',
              sub_start: 1,
              sub_end: 0,
            }), { headers: headers })
            .map(res => res.json()).subscribe(response => {
            }, error => {              
              alert('Error: Something went wrong, please try again later.');
            });

        }
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  collapseNav() {
    if (this.isNavbarCollapsed === false) {
      this.isNavbarCollapsed = true;
    }
    window.scroll(0, 0);
  }

  getTs() {
    return new Date().getTime();
  }
}
