import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Http, Headers } from '@angular/http';

import { environment } from '../../../environments/environment';
import { WindowService } from '../../system/window.service';
import { SystemService } from '../../system/system.service';
import * as firebase from 'firebase/app';
import * as mixpanel from 'mixpanel-browser';
import * as Parse from 'parse';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  user = {
    username: null,
    name: null,
    email: null,
    password: '123456',
    mobile: null,
    isReferralEligible: true
  };

  first_time = false;

  environment = environment;
  state = JSON.stringify({});

  phone = null;
  countryCode = '91';

  windowRef: any;

  viewPhoneCode = false;

  phoneVerifCode = null;

  constructor(
    private title: Title,
    private meta: Meta,
    public afAuth: AngularFireAuth,
    public win: WindowService,
    private route: ActivatedRoute,
    private router: Router,
    private system: SystemService,
    private http: Http

  ) {
    this.route.queryParams.subscribe(params => {
      if (typeof params.next !== 'undefined') {
        this.state = JSON.stringify({ next: params.next });
      }
    });
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    this.title.setTitle('Signin to account');
    mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Login or Signup');
    this.windowRef = this.win.windowRef;
  }

  ngAfterContentInit() {
    // setTimeout(function() {
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signinBtn', {
    //   'size': 'invisible'
    // });
    // }, 2000);
  }

  phoneSignin() {
    this.viewPhoneCode = true;
    const phoneNumber = this.phone;

    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptchaWrap', {
      'size': 'invisible'
    });
    this.afAuth.auth.signInWithPhoneNumber('+' + this.countryCode + phoneNumber, appVerifier).then(res => {
      this.windowRef.confirmationResult = res;
    }, phoneNumber).catch(err => {
      console.log(err);
      alert(err.message);
    });
  }

  verifyCode() {
    const phoneNumber = this.countryCode + this.phone;
    this.windowRef.confirmationResult.confirm(this.phoneVerifCode.toString()).then(p => {
      const gotoLocation = window.location.protocol + '//' + window.location.host
        + '/account/oauth/process/signin/phone?phoneNumber=' + phoneNumber;
      // gotoLocation = response.notes.next ? gotoLocation + '&next=' + response.notes.next : gotoLocation;
      window.location.href = gotoLocation;
    }, phoneNumber).catch(err => {
      console.log(err);
      alert(err.message);
    });
  }

  async facebookSignin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    const users = Parse.Object.extend('User');

    const query = new Parse.Query(users);

    query.equalTo('email', credential.user.email);
    query.find().then(async (results) => {
      if (results.length > 0) {
        this.system.USER = results[0];
        // this.cookie.set(
        //   'user',
        //   results[0]['id'],
        //   new Date(new Date().setDate(new Date().getDate() + 365)),
        //   '/',
        //   '.' + environment.APP_URL,
        //   environment.COOKIE_SECURE,
        //   'Lax'
        // );

        localStorage.setItem('user', results[0]['id'].toString());
        this.mxTrackUserAction(
          results[0].attributes.username,
          results[0].attributes.name,
          (typeof (results[0].attributes.email) !== 'undefined') ? results[0].attributes.email : null,
          (typeof (results[0].attributes.mobile) !== 'undefined') ? results[0].attributes.mobile : null
        );

        // mixpanel.people.get();

        this.system.IS_LOGIN = true;
        this.router.navigate(['account/profile']);
      } else {
        this.user.name = credential.user.displayName;
        this.user.email = credential.user.email;
        this.first_time = true;
      }
    });
  }

  async googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    const users = Parse.Object.extend('User');

    const query = new Parse.Query(users);

    query.equalTo('email', credential.user.email);
    query.find().then(async (results) => {
      if (results.length > 0) {
        this.system.USER = results[0];
        // this.cookie.set(
        //   'user',
        //   results[0]['id'],
        //   new Date(new Date().setDate(new Date().getDate() + 365)),
        //   '/',
        //   '.' + environment.APP_URL,
        //   environment.COOKIE_SECURE,
        //   'Lax'
        // );

        localStorage.setItem('user', results[0]['id'].toString());
        this.mxTrackUserAction(
          results[0].attributes.username,
          results[0].attributes.name,
          (typeof (results[0].attributes.email) !== 'undefined') ? results[0].attributes.email : null,
          (typeof (results[0].attributes.mobile) !== 'undefined') ? results[0].attributes.mobile : null
        );

        // mixpanel.people.get();

        this.system.IS_LOGIN = true;
        if (results[0].attributes.user_type === 'BB') {
          this.router.navigate(['tender/dashboard']);
        } else {
          this.router.navigate(['account/profile']);
        }
      } else {
        this.user.name = credential.user.displayName;
        this.user.email = credential.user.email;
        this.first_time = true;

        const headers = new Headers();
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
        headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);
        headers.append('X-Parse-Revocable-Session', '1');

        this.http.post(this.system.generateRequestUrl('/classes/_User', null, environment.serverURL),
          JSON.stringify({
            name: `${credential.user.displayName}`,
            username: `${credential.user.phoneNumber ? credential.user.phoneNumber : credential.user.email}`,
            mobile: credential.user.phoneNumber ? `+91${credential.user.phoneNumber}` : null,
            email: `${credential.user.email}`,
            password: this.makeid(10)
          }), { headers: headers }).map(res => res.json()).subscribe(response => {
            this.system.IS_LOGIN = true;
            localStorage.setItem("user", response.objectId.toString());
            this.router.navigate(['account/profile']);
          }, error => {

          });
      }
    });
  }


  mxTrackUserAction(username, name, email, mobile) {
    mixpanel.identify(username);
    mixpanel.people.set('$name', name);
    mixpanel.people.set('$email', email);
    mixpanel.people.set('$phone', mobile);
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

}
