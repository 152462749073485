import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  appURL = environment.APP_URL;

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Privacy Policy');
  }

}
