import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SystemService } from '../../system/system.service';
import { environment } from '../../../environments/environment';



import * as Parse from 'parse';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ProductCreateComponent implements OnInit {

  @ViewChild('modalProductCreated') modalProductCreated: ElementRef;
  @ViewChild('modalUpdateCreated') modalUpdateCreated: ElementRef;

  product = {
    currency: '₹',
    list_category: 'vegetables',
    list_desc: '',
    list_email: '',
    list_freq: 'O',
    list_img: null,
    list_loc: '',
    list_mobile: '',
    list_name: '',
    list_price: '',
    list_qty: '',
    list_title: '',
    list_type: 'B',
    list_id: null,
    listedBy: { __type: 'Pointer', className: '_User', objectId: null },
    active: null,
    verified: false
  };

  listingStage = 'create';
  listStep = 'first';

  user;
  imageSrc = null;
  listUnit = 'kg';

  fileToUpload: File = null;

  listing_id = null;

  tender = {
    list_id: null,
    t_min_qty: null,
    t_spec: null,
    t_end_date: null,
    t_delivery_address: null,
    t_delivery_date: null,
    t_payment_terms: null,
    t_status: 'O',
    t_offers_count: 0,
    t_offered_tot_qty: null,
  }


  listingFormFirstStage: FormGroup;
  listingFormSecondStage: FormGroup;
  premium: boolean = false;


  constructor(private http: Http, public system: SystemService,
    private router: Router, private title: Title, private meta: Meta,
    private route: ActivatedRoute, private formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;
    this.title.setTitle('Create Listing');
  }

  ngOnInit() {
    this.premium = this.system.IS_PREMIUM;
    this.route.queryParams.subscribe(params => {
      console.log('params: ', params);
      if (typeof params.listing_id !== 'undefined') {
        this.listingFormFirstStage = this.formBuilder.group({
          'list_name': ['', [Validators.required, Validators.minLength(4)]],
          'list_loc': ['', [Validators.required, Validators.minLength(4)]]
        });
        this.listingFormSecondStage = this.formBuilder.group({
          'list_qty': ['', [Validators.required, Validators.minLength(1)]],
          'list_price': ['', [Validators.required, Validators.minLength(1)]],
        });
        this.getItem(params.listing_id);
      } else {
        this.listingFormFirstStage = this.formBuilder.group({
          'list_name': ['', [Validators.required, Validators.minLength(4)]],
          'list_loc': ['', [Validators.required, Validators.minLength(4)]],
        });
        this.listingFormSecondStage = this.formBuilder.group({
          'list_qty': ['', [Validators.required, Validators.minLength(1), Validators.pattern('^[0-9]+(.[0-9]{0,3})?$')]],
          'list_price': ['', [Validators.required, Validators.minLength(1), Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
        });
      }
    });

    const users = Parse.Object.extend('User');
    const query = new Parse.Query(users);

    query.equalTo('objectId', localStorage.getItem('user'));
    // query.equalTo('objectId', '1GMHG40Z1o');
    query.find().then((results) => {
      console.log(results);
      if (results.length > 0) {
        this.user = results[0];
      }
    });
  }

  getItem(id) {


    const listing = Parse.Object.extend('ff_newlisting');

    const query = new Parse.Query(listing);

    query.equalTo('objectId', id);
    query.equalTo('listedBy', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));
    query.descending('createdAt');
    query.find().then((results) => {
      console.log(results);

      this.product = {
        currency: results[0].attributes.currency,
        list_category: results[0].attributes.list_category,
        list_desc: results[0].attributes.list_desc,
        list_email: results[0].attributes.list_email,
        list_freq: results[0].attributes.list_freq,
        list_img: results[0].attributes.list_img,
        list_loc: results[0].attributes.list_loc,
        list_mobile: results[0].attributes.list_mobile,
        list_name: results[0].attributes.list_name,
        list_price: results[0].attributes.list_price,
        list_qty: results[0].attributes.list_qty,
        list_title: results[0].attributes.list_title,
        list_type: results[0].attributes.list_type,
        list_id: results[0].attributes.list_id,
        listedBy: { __type: 'Pointer', className: '_User', objectId: results[0].attributes.listedBy.objectId },
        active: results[0].attributes.active,
        verified: results[0].attributes.verified
      };

      this.listing_id = results[0].id;
      this.product.active = results[0].attributes.active;

    }, (error) => {
      console.log(error);
    });


  }

  saveProduct() {
    this.listingStage = 'progress';

    if (this.listingFormFirstStage.valid && this.listingFormSecondStage.valid) {
      this.listingFormFirstStage = this.formBuilder.group({
        'list_name': ['', []],
        'list_loc': ['', []],
      });
      this.listingFormSecondStage = this.formBuilder.group({
        'list_qty': ['', []],
        'list_price': ['', []],
      });
      console.log(this.fileToUpload);
      console.log(this.user);

      this.product.listedBy.objectId = this.user.id;
      this.product.list_name = this.user.attributes.name;
      this.product.list_email = this.user.attributes.email;
      this.product.list_mobile = this.user.attributes.mobile;
      this.product.verified = this.user.attributes.verified;

      console.log(this.product);

      const headers = new Headers();
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      // this.product.list_img = this.fileToUpload;
      // this.saveData();

      if (this.fileToUpload) {
        headers.append('Content-Type', this.fileToUpload.type);

        this.http.post(this.system.generateRequestUrl('/files/' + this.fileToUpload.name, null, environment.serverURL),
          this.fileToUpload, { headers: headers })
          .map(res => res.json()).subscribe(image => {
            console.log(image);
            this.product.list_img = {
              __type: 'File',
              name: image.name
            };
            if (this.listing_id || typeof this.product.active === 'undefined') {
              this.updateData();
            } else {
              this.saveData();
            }
          });
      } else {
        if (this.listing_id || typeof this.product.active === 'undefined') {
          this.updateData();
        } else {
          delete this.product.list_img;
          this.saveData();
        }
      }
    } else {
      this.listingStage = 'create';
      this.validateAllFormFields(this.listingFormFirstStage);
      this.validateAllFormFields(this.listingFormSecondStage);

    }
  }

  saveData() {
    
    delete this.product.active;

    if (!this.listing_id) {
      this.product.list_price = this.product.currency + ' ' + this.product.list_price + ' / ' + this.listUnit;
      this.product.list_qty = this.product.list_qty + ' ' + this.listUnit;
      this.product.list_id = this.dateId();
    }
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_newlisting', null, environment.serverURL),
      JSON.stringify(this.product), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        this.listingStage = 'save_complete';
        console.log(response);
        this.listing_id = response.objectId;
        // alert('Thank you for considering FarmFirst. Please wait while your request is being processed!');
        // this.openModelListingComplete(response.objectId);
        if (this.product.list_type === 'T') {
          this.getItemListId(this.listing_id);
        }
      }, error => {
        this.listingStage = 'error';
      });
  }

  getItemListId(id) {
    const listing = Parse.Object.extend('ff_newlisting');
    const query = new Parse.Query(listing);

    query.equalTo('objectId', id);
    query.equalTo('listedBy', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));
    query.find().then((results) => {
      console.log(results);
      this.setTender(results[0].attributes.list_id);
    });
  }

  setTender(id) {
    this.tender.list_id = id;

    let qty = this.product.list_qty;
    let split = qty.split(" ");
    let total = split[0];
    this.tender.t_offered_tot_qty = parseInt(total);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_tenders', null, environment.serverURL),
      JSON.stringify(this.tender), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        // this.listingStage = 'save_complete';
        console.log("tender set", response);
        // this.listing_id = response.objectId;
        // alert('Thank you for considering FarmFirst. Please wait while your request is being processed!');
        // this.openModelListingComplete(response.objectId);
      }, error => {
        this.listingStage = 'error';

      });
    console.log("tender details", this.tender);

  }

  updateData() {

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.put(this.system.generateRequestUrl('/classes/ff_newlisting/' + this.listing_id, null, environment.serverURL),
      JSON.stringify(this.product), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        this.listingStage = 'update_complete';
        console.log(response);
        // alert('Please wait while your request is being processed!');
        // this.openModelUpdateRequest(response.objectId);
      }, error => {
        this.listingStage = 'error';
      });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
    const file = files[0];

    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;

    reader.readAsDataURL(file);

  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCss(field: string) {
    return {
      'error': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return (this.listingFormFirstStage.get(field)
      && !this.listingFormFirstStage.get(field).valid
      && this.listingFormFirstStage.get(field).touched) ||
      (this.listingFormSecondStage.get(field)
        && !this.listingFormSecondStage.get(field).valid
        && this.listingFormSecondStage.get(field).touched);
  }

  dateId() {
    const today = new Date();

    const dd = ('0' + (today.getDate().toString())).substr(-2);
    const mm = ('0' + ((today.getMonth() + 1).toString())).substr(-2);
    const yyyy = ('000' + (today.getFullYear().toString())).substr(-4);

    const hh = ('0' + (today.getHours().toString())).substr(-2);
    const M = ('0' + (today.getMinutes().toString())).substr(-2);

    return yyyy + mm + dd + hh + M;
  }

  // openModelUpdateRequest(id) {
  //   this.modalService.open(this.modalProductCreated).dismiss((action) => {
  //     this.router.navigate(
  //       ['product/' + this.system.seo_url_generate(
  //         this.product.list_type,
  //         this.product.list_title,
  //         this.product.list_loc)
  //       ],
  //       {
  //         queryParams: { id: id }
  //       }
  //     );
  //   });
  // }

  // openModelListingComplete(id) {
  //   this.modalService.open(this.modalProductCreated).dismiss((action) => {
  //     this.router.navigate(
  //       ['product/' + this.system.seo_url_generate(
  //         this.product.list_type,
  //         this.product.list_title,
  //         this.product.list_loc)
  //       ],
  //       {
  //         queryParams: { id: id }
  //       }
  //     );
  //   });
  // }

  reload() {
    // this.product = {
    //   currency: '₹',
    //   list_category: 'vegetables',
    //   list_desc: '',
    //   list_email: '',
    //   list_freq: 'O',
    //   list_img: null,
    //   list_loc: '',
    //   list_mobile: '',
    //   list_name: '',
    //   list_price: '',
    //   list_qty: '',
    //   list_title: '',
    //   list_type: 'B',
    //   list_id: null,
    //   listedBy: { __type: 'Pointer', className: '_User', objectId: null },
    //   active: undefined,
    //   verified: false
    // };

    // this.listingStage = 'create';
    // this.listStep = 'first';

    this.router.navigateByUrl('/account/profile', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/product/create'], { queryParams: { rf: true } });
    });
  }

  viewListing(id) {
    this.router.navigate(
      ['product/' + this.system.seo_url_generate(
        this.product.list_type,
        this.product.list_title,
        this.product.list_loc)
      ],
      {
        queryParams: { id: id }
      }
    );
  }

}
