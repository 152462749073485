import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { SystemService } from '../../system/system.service';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import * as Parse from 'parse';


@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss']
})
export class OfferFormComponent implements OnInit {
  offer = {
    list_id: null,
    bidder_id: null,
    o_qty: null,
    o_price: null,
    o_date_of_delivery: null,
    o_farm_address: null,
    o_comments: null,
    o_submitted_date: null,
    o_status: 'S'
  };

  user = {
    objectId: null,
    name: null,
    mobile: null,
    email: null,
    city: null,
    state: null,
    country: null,
    pin: null,
    id_proof: null,
    address_proof: null,
    bus_proof: null,
    user_type: null,
  };

  measures: any = null;
  country: any = null;
  state: any = null;
  district: any = null;
  tender: any;
  tenderMeasure: any;
  errorMin: boolean = false;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  authToken: any;
  item = [];
  listing_id: any;

  constructor(private http: Http,
    public system: SystemService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY, 'dfsfsd');
    (Parse as any).serverURL = environment.serverURL;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log('params', params);

      if (params.listing_id) {
        this.listing_id = params.listing_id;
        this.getItem();
      }
    });
    this.getToken();
    this.getUser();
  }

  getToken() {
    const headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('api-token', 'G9Sx7YqMaN8GcJ7HfELgEkb5o0e0BNn5V9FJBacCYK-yJjqpLn4eyTs0oMGLZMSIDzc');
    headers.append('user-email', 'ananthugvr@gmail.com');


    this.http.get('https://www.universal-tutorial.com/api/getaccesstoken', { headers: headers })
      .subscribe((response: any) => {
        let token = JSON.parse(response._body);
        this.authToken = token.auth_token;
        console.log('auth token', this.authToken);
        this.getCountry();
      });
  }

  getCountry() {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/countries/', { headers: headers })
      .subscribe((response: any) => {

        let country = JSON.parse(response._body)
        this.countries = country;
        console.log(this.countries);
      });
  }
  countrySelected() {
    console.log('country selected');
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/states/' + this.country, { headers: headers })
      .subscribe((response: any) => {

        let state = JSON.parse(response._body)
        this.states = state;
        console.log(this.states);
      });
  }
  StateSelected() {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.authToken);

    this.http.get('https://www.universal-tutorial.com/api/cities/' + this.state, { headers: headers })
      .subscribe((response: any) => {

        let city = JSON.parse(response._body)
        this.cities = city;
        console.log(this.cities);
      });
  }

  makeOffer() {

    this.offer.list_id = this.item[0].attributes.list_id;
    this.offer.bidder_id = { __type: 'Pointer', className: '_User', objectId: this.user.objectId }
    this.offer.o_qty = this.offer.o_qty + ' ' + this.measures;
    this.offer.o_farm_address = this.offer.o_farm_address + ',' + this.country + ',' + this.state + ',' + this.district;
    this.offer.o_submitted_date = this.getDate();
    console.log(this.offer);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_offers', null, environment.serverURL),
      JSON.stringify(this.offer), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);
        this.router.navigate(['/products']);
      }, error => {

      });

  }

  getItem() {
    const listing = Parse.Object.extend('ff_newlisting');
    const query = new Parse.Query(listing);

    // query.equalTo('active', true);
    query.equalTo('objectId', this.listing_id);
    query.descending('createdAt');
    query.find().then(async (results) => {
      console.log('form reslutttttttt', results);
      this.item = results;
      if (this.item[0].attributes.list_type === 'T') {
        console.log('item()=>', this.item[0].attributes);

        this.getTender(this.item[0].attributes.list_id);
        let qty = this.item[0].attributes.list_qty;
        let split = qty.split(' ');
        this.tenderMeasure = split[1];
        this.measures = this.tenderMeasure;
      }
    })

  }

  getTender(id) {
    console.log('tender()=>list_id', id);

    const tender = Parse.Object.extend('ff_tenders');
    const queryTender = new Parse.Query(tender);
    queryTender.equalTo('list_id', id);
    queryTender.find().then((res: any) => {
      this.tender = res[0].attributes;
    });

  }

  getDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let day = yyyy + '-' + mm + '-' + dd;
    return day;
  }

  checkMinimum() {
    console.log('minimum');
    if (this.offer.o_qty < this.tender.t_min_qty) {
      this.errorMin = true;
    }
    else {
      this.errorMin = false;
    }

  }

  getUser() {
    if (localStorage.getItem('user')) {
      const headers = new Headers();

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + localStorage.getItem('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
          console.log('user data', this.user);

        });
    }
  }
  cancel() {

  }
}
