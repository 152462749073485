import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('About FarmFirst');
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
    this.meta.addTags([
      {
        name: 'description', content: 'Farmfirst started on March 2015. Launched the Mobile app on 2nd May 2015, '
          + 'and was got first 1000 installations on the first month itself, and currenly getting more than 1000 instllations/month.'
          + 'Registred as an LLP on 9th Sep 2015.Started online order processing on Jul 2016'
      },
      {
        name: 'keywords', content: 'FirmFirst, March 2015, LLP Registred, for wholesale buyers, sellers, traders, '
          + 'exporters, importers, agents, suppliers and farmers. Freshly hand-picked, assured quality, Price guarantee, delivery'
          + 'Onions, Potatoes, Rice, Wheat, Cereals, Lemon, Seeds and Plants, Fruits and Fresh Vegetables,'
          + 'Organics, exotic English vegetables, Cashew, nuts, dry fruits, Curry leaves, Banana, Green Chilies, All'
          + 'spices, Coconut, Sugar, edible and essential Oils, Eggs, Maize, seedlings, flowers, plants and more'
      }
    ]);
  }

}
