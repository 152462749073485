import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Http, Headers } from '@angular/http';

import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

import * as Parse from 'parse';
import { stringify } from 'querystring';

@Component({
  selector: 'app-tender-dashboard',
  templateUrl: './tender-dashboard.component.html',
  styleUrls: ['./tender-dashboard.component.scss']
})
export class TenderDashboardComponent implements OnInit {

  user = {
    objectId: null,
    name: null,
    mobile: null,
    email: null,
    city: null,
    state: null,
    country: null,
    pin: null,
    id_proof: null,
    address_proof: null,
    bus_proof: null,
    user_type: null,
  };
  count = 0;
  limit = 100;

  listings = [];
  loading = true;
  error = false;

  search_query = null;
  search_filter = 'all';

  fileToUpload: File = null;
  idProofSrc = null;
  addressProofSrc = null;
  businessProofSrc = null;
  options = 'form';
  buyerView = "list";
  sellerView = "list";
  BidArray: any[] = [];
  bids: any[] = [];
  currentItem: any;
  currentBid: any;
  submittedOffers: any[] = [];
  currentSellerOffer = {
    offer: {
      list_id: null,
      bidder_id: null,
      o_qty: null,
      o_price: null,
      o_date_of_delivery: null,
      o_farm_address: null,
      o_comments: null,
      o_submitted_date: null,
      o_status: 'S'
    },
    listing: null,
    offerId: null,
    listId: null,

  };
  currentBidId: any = null;
  constructor(private http: Http, public system: SystemService, private router: Router,
    private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('My Profile');

    // un comment for buyer mode Sreeraj karippala
    // this.cookie.set('user','wvCVg5DMtN');

    //un comment for seller Mode Ananthu
    // this.cookie.set('user','5mkV2VluaG'); 

    console.log("set cookieee", localStorage.getItem('user'));


    if (localStorage.getItem('user')) {
      const headers = new Headers();

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + localStorage.getItem('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
          if (this.user.user_type === 'BB') {
            this.system.IS_PREMIUM = true;
          }
          if (this.user.user_type === 'CF') {
            this.system.IS_PREMIUM = true;
          }
          this.getProductListings();
          this.getUserSubmittedOffers();
        });

      // Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
      // (Parse as any).serverURL = environment.serverURL;

      // const listing = Parse.Object.extend('ff_newlisting');
      // const query = new Parse.Query(listing);

      // if (this.search_filter === 'buy') {
      //   query.equalTo('list_type', 'B');
      // } else if (this.search_filter === 'sell') {
      //   query.equalTo('list_type', 'S');
      // }else if (this.search_filter === 'tender') {
      //   query.equalTo('list_type', 'T');
      // }

      // if (this.search_query) {
      //   query.matches('list_title', this.search_query, 'i');
      // }

      // query.equalTo('listedBy', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));

      // query.descending('createdAt');
      // query.find().then((results) => {
      //   this.listings = results;
      //   this.loading = false;
      //   this.error = false;
      //   console.log(results);
      // }, (error) => {
      //   this.loading = false;
      //   this.error = true;

      //   console.log(error);
      // });
    }

  }


  getProductListings() {

    const listing = Parse.Object.extend('ff_newlisting');
    const queryList = new Parse.Query(listing);

    // if (this.search_filter === 'buy') {
    //   query.equalTo('list_type', 'B');
    // } else if (this.search_filter === 'sell') {
    //   query.equalTo('list_type', 'S');
    // }

    // if (this.search_query) {
    //   mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Searched');
    //   this.search_filter = 'all';
    //   query.matches('list_title', this.search_query, 'i');
    // }

    // Assume Parse.Object myPost was previously created.

    // queryList.equalTo('active', true);
    queryList.equalTo('listedBy', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));
    queryList.descending('createdAt');
    queryList.limit(this.limit);
    queryList.skip(this.count * this.limit);
    queryList.find().then((results) => {

      results.forEach(element => {
        this.getListingOffers(element, this.user.user_type);
      });
      // this.listings = this.listings.concat(results);
    }, (error) => {
      console.log(error);
    });
    console.log("bid array()=>", this.BidArray);
  }

  getListingOffers(element, type) {
    this.BidArray = [];
    let qty = element.attributes.list_qty;
    let title = element.attributes.list_title;
    let id = element.attributes.list_id;
    let listId = element.id;

    console.log("type", type);
    let attributes = [];
    let measure = 0;
    let amount = '0';
    const Offers = Parse.Object.extend('ff_offers');
    const queryOffer = new Parse.Query(Offers);
    queryOffer.equalTo('list_id', id);
    queryOffer.include('User')
    queryOffer.find().then((res: any) => {


      console.log("ff_offers()=>", res);

      if (res.length > 0) {
        const headers = new Headers();

        headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
        headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);
        res.forEach((element, offerIdx) => {
          attributes.push({ "bid": element.attributes, "id": element.id });

          this.http.get(this.system.generateRequestUrl('/classes/_User/' + element.attributes.bidder_id.id,
            {}, environment.serverURL), { headers: headers })
            .map(res => res.json()).subscribe(response => {
              attributes[offerIdx].bidder = response;
              amount = element.attributes.o_qty;
              measure = measure + parseInt(amount.slice(0, -2));
            });

        });
        console.log(attributes);

        if (type === 'BB') {
          const Tenders = Parse.Object.extend('ff_tenders');
          const queryTender = new Parse.Query(Tenders);
          queryTender.equalTo('list_id', id);
          queryTender.find().then((res: any) => {
            console.log("tenderrrrrr", res[0].objectId);

            this.BidArray.push({ 'title': title, 'measure': measure, 'count': attributes.length, 'bids': attributes, 'listing': element.attributes, 'tender': res[0].attributes, "list_id": listId });
          });
        }
        else {
          this.BidArray.push({ 'title': title, 'measure': measure, 'count': attributes.length, 'bids': attributes, 'listing': element.attributes, 'tender': null, "list_id": listId });
        }
      }
    })
  }


  bidStatus(opt) {
    let update = { "o_status": null }
    if (opt === 'accept') {
      update.o_status = 'A'
    }
    if (opt === 'reject') {
      update.o_status = 'R'
    }

    console.log("current Bid", this.currentBidId);

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.put(this.system.generateRequestUrl('/classes/ff_offers/' + this.currentBidId, null, environment.serverURL),
      JSON.stringify(update), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);
        this.getProductListings();
      }, error => {

      });
  }



  async getUserSubmittedOffers() {
    this.submittedOffers = [];
    const Offer = Parse.Object.extend('ff_offers');
    const queryOffer = new Parse.Query(Offer);
    queryOffer.equalTo('bidder_id', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));
    queryOffer.find().then((res: any) => {
      console.log("offerrrrrrrr", res);
      res.forEach(element => {
        this.getListingItem(element);
      });

    });
    console.log("submit offers", this.submittedOffers);

  }

  getListingItem(element) {
    const list = Parse.Object.extend('ff_newlisting');
    const queryList = new Parse.Query(list);
    queryList.equalTo('list_id', element.attributes.list_id);
    queryList.find().then((res: any) => {
      console.log("list itemmmmmmmmmm", res);
      this.submittedOffers.push({ 'offer': element.attributes, 'listing': res[0].attributes, 'offerId': element.id, "listId": res[0].id });
    });

  }

  updateOfferData() {
    let qty = this.currentSellerOffer.offer.o_qty.quantity;
    let mes = this.currentSellerOffer.offer.o_qty.measure;
    this.currentSellerOffer.offer.o_qty = qty + ' ' + mes;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.put(this.system.generateRequestUrl('/classes/ff_offers/' + this.currentSellerOffer.offerId, null, environment.serverURL),
      JSON.stringify(this.currentSellerOffer.offer), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        console.log(response);
        this.getUserSubmittedOffers();
        this.changeSellerView('list', null);
      }, error => {

      });
  }

  changeView(opt: string, item) {
    this.buyerView = opt;

    if (item !== null) {
      console.log("itemmmmmssssssss", item.bids);
      this.bids = item.bids;
      this.currentItem = item;
    }

  }

  viewDetails(opt, bid, id) {
    this.buyerView = opt;
    console.log("sssssss", bid, this.currentItem);
    this.currentBid = bid;
    this.currentBidId = id;

  }

  changeSellerView(opt: string, item) {
    this.sellerView = opt;
    this.currentSellerOffer = {
      offer: {
        list_id: item.offer.list_id,
        bidder_id: { __type: 'Pointer', className: '_User', objectId: this.user.objectId },
        o_qty: null,
        o_price: item.offer.o_price,
        o_date_of_delivery: item.offer.o_date_of_delivery,
        o_farm_address: item.offer.o_farm_address,
        o_comments: item.offer.o_comments,
        o_submitted_date: item.offer.o_submitted_date,
        o_status: item.offer.o_status
      },
      listing: item.listing,
      offerId: item.offerId,
      listId: item.listId
    }
    let measure = item.offer.o_qty;
    let split = measure.split(" ");
    console.log(split, "split()=>");
    this.currentSellerOffer.offer.o_qty = { 'quantity': split[0], 'measure': split[1] };

  }

}
