import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Http, Headers } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';



import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

declare const Razorpay: any;
import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  plans = [];
  planDetails = null;
  pageView = 'planDetails';
  user = null;
  next = null;
  enableNext = false;

  constructor(
    private http: Http,
    public system: SystemService,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private router: Router
    
  ) {
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    this.title.setTitle('Pricing and Plans');

    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;

    this.route.queryParams.subscribe(params => {
      if (typeof params.next !== 'undefined') {
        this.next = params.next;
      }
    });

    mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Price Viewed');

    if (localStorage.getItem('user')) {
      const headers = new Headers();
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

      this.http.get(this.system.generateRequestUrl('/classes/_User/' + localStorage.getItem('user'),
        {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          console.log(response);
          this.user = response;
        });
    }

    this.plansList();
  }

  plansList() {
    const listing = Parse.Object.extend('ff_plans');
    const query = new Parse.Query(listing);

    this.route.queryParams.subscribe(params => {
      if (typeof params.plan_id !== 'undefined') {
        query.notEqualTo('objectId', params.plan_id);
      }
    });

    query.ascending('amount');

    query.find().then((results) => {
      console.log(results);
      this.plans = results;
    }, (error) => {
      console.log(error);
    });
  }

  getPlanDetails(plan_id) {
    const listing = Parse.Object.extend('ff_plans');
    const query = new Parse.Query(listing);

    query.equalTo('objectId', plan_id);
    query.first().then((results) => {
      console.log(results);
      this.planDetails = results;
      this.payNow(this.planDetails.attributes.amount, this.planDetails.attributes.duration);
    }, (error) => {
      console.log(error);
    });
  }

  payNow(amount, days = 0) {
    const options = {
      'key': environment.RAZORPAY_API_KEY,
      'name': ' MARKET',
      'description': 'Order #',
      'amount': amount * 100,
      'handler': function (response) {
        console.log(response);
        const gotoLocation = window.location.protocol + '//' + window.location.host
          + '/account/subscription?payment_id=' + response.razorpay_payment_id;
        // gotoLocation = response.notes.next ? gotoLocation + '&next=' + response.notes.next : gotoLocation;
        window.location.href = gotoLocation;
      },
      'prefill': {
        'name': this.user.name,
        'email': this.user.email,
        'contact': this.user.mobile,
      },
      'notes': {
        days: days,
        user_id: this.user.objectId,
        next: this.next
      },
    };
    const rzp1 = new Razorpay(options);

    rzp1.open();
  }

}
