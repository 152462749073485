import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from '../../system/window.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';

import * as firebase from 'firebase/app';
import * as mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  environment = environment;
  state = JSON.stringify({});

  phone = null;
  windowRef: any;
  viewPhoneCode = false;
  phoneVerifCode = null;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    public afAuth: AngularFireAuth,
    public win: WindowService,
  ) {
    this.route.queryParams.subscribe(params => {
      if (typeof params.next !== 'undefined') {
        this.state = JSON.stringify({ next: params.next });
      }
    });
    mixpanel.init(environment.MIXPANEL_TOKEN);
  }

  ngOnInit() {
    this.title.setTitle('Signup an account');
    mixpanel.track(environment.MIXPANEL_TRACK_PREFIX + 'Login or Signup');
  }

  phoneSignin() {
    this.viewPhoneCode = true;
    const phoneNumber = this.phone;

    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptchaWrap', {
      'size': 'invisible'
    });
    this.afAuth.auth.signInWithPhoneNumber('+91' + phoneNumber, appVerifier).then(res => {
      this.windowRef.confirmationResult = res;
    }, phoneNumber).catch(err => {
      console.log(err);
      alert(err.message);
    });
  }

  verifyCode() {
    const phoneNumber = this.phone;
    this.windowRef.confirmationResult.confirm(this.phoneVerifCode.toString()).then(p => {
      const gotoLocation = window.location.protocol + '//' + window.location.host
        + '/account/oauth/process/signup/phone?phoneNumber=' + phoneNumber;
      // gotoLocation = response.notes.next ? gotoLocation + '&next=' + response.notes.next : gotoLocation;
      window.location.href = gotoLocation;
    }, phoneNumber).catch(err => {
      alert(err.message);
    });
  }

}
