import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SystemService } from '../../system/system.service';

import * as Parse from 'parse';
import * as mixpanel from 'mixpanel-browser';
@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {

  listings = [];

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    public system: SystemService,
    private router: Router
  ) {
    Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;
  }

  ngOnInit() {
    this.getListings();
  }

  getListings() {

    const listing = Parse.Object.extend('ff_newlisting');
    const query = new Parse.Query(listing);

    query.equalTo('active', true);
    query.descending('createdAt');
    query.limit(250);
    query.find().then((results) => {
      this.listings = results;
      console.log(results);
    }, (error) => {
      console.log(error);
    });
  }

}
