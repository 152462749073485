import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './system/auth.guard';

import { HomeComponent } from './pages/home/home.component';
import { ProductComponent } from './pages/product/product.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermPolicyComponent } from './pages/term-policy/term-policy.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { AboutComponent } from './pages/about/about.component';
import { SigninComponent } from './account/signin/signin.component';
import { SignupComponent } from './account/signup/signup.component';
import { ProfileComponent } from './account/profile/profile.component';
import { ProcessComponent } from './account/oauth/process/process.component';
import { ProductCreateComponent } from './pages/product-create/product-create.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { SignoutComponent } from './account/signout/signout.component';
import { SubscriptionComponent } from './account/subscription/subscription.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { ProductListOldComponent } from './pages/product-list-old/product-list-old.component';
import { TenderDashboardComponent } from './pages/tender-dashboard/tender-dashboard.component';
import { OfferFormComponent } from './pages/offer-form/offer-form.component';
import { DeleteComponent } from './account/delete/delete.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'product/create', canActivate: [AuthGuard], component: ProductCreateComponent },
  { path: 'product/:term', component: ProductComponent },
  { path: 'schema/sitemap/:idx', component: SitemapComponent },
  { path: 'search', component: ProductListComponent },
  { path: 'search/:term', component: ProductListComponent },
  { path: 'products', component: ProductListComponent },
  { path: 'products/old', component: ProductListOldComponent },
  { path: 'products/old/:query', component: ProductListOldComponent },
  { path: 'products/old/:type/:query', component: ProductListOldComponent },
  { path: 'products/:query', component: ProductListComponent },
  { path: 'products/:type/:query', component: ProductListComponent },
  { path: 'policy/privacy', component: PrivacyPolicyComponent },
  { path: 'policy/term', component: TermPolicyComponent },
  { path: 'policy/refund', component: RefundPolicyComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'tender/dashboard', component: TenderDashboardComponent },
  { path: 'account/signin', component: SigninComponent },
  { path: 'account/signup', component: SignupComponent },
  { path: 'account/signout', component: SignoutComponent },
  { path: 'account/profile', canActivate: [AuthGuard], component: ProfileComponent },
  { path: 'account/subscription', canActivate: [AuthGuard], component: SubscriptionComponent },
  { path: 'account/delete', canActivate: [AuthGuard], component: DeleteComponent },
  { path: 'account/oauth/process', component: ProcessComponent },
  { path: 'account/oauth/process/:for', component: ProcessComponent },
  { path: 'account/oauth/process/:for/:type', component: ProcessComponent },
  { path: 'offerForm', component: OfferFormComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
