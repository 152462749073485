import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Http, Headers } from '@angular/http';

import { SystemService } from '../../system/system.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  textAreaDisabled = true;
  fbButtonDisabled = true;
  feedback = null;
  feedbackSuccess = false;

  user = {
    objectId: null,
    mobile: null,
    name: null,
    username: null
  };

  constructor(
    private title: Title,
    private meta: Meta,
    public system: SystemService,
    private http: Http,
  ) { }

  ngOnInit() {
    this.title.setTitle('Contact FarmFirst');
    this.textAreaDisabled = this.system.IS_LOGIN === true ? false : true;
    if (this.system.IS_LOGIN) {
      this.getUser();
    }
  }

  submitFeedback() {
    const user_id = localStorage.getItem('user');

    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/classes/ff_feedback', null, environment.serverURL),
      JSON.stringify({
        mobile: this.user.mobile,
        feedback_msg: this.feedback,
        name: this.user.name,
        user: { __type: 'Pointer', className: '_User', objectId: this.user.objectId },
        username: this.user.username,
      }), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        this.feedbackSuccess = true;
      }, error => {

      });
  }

  getUser() {

    const headers = new Headers();
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.get(this.system.generateRequestUrl('/classes/_User/' + localStorage.getItem('user'),
      {}, environment.serverURL), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        this.user = response;
      });

  }
}
