import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Http, Headers } from '@angular/http';

import { environment } from '../../../environments/environment';
import { SystemService } from '../../system/system.service';

import * as Parse from 'parse';
import { stringify } from 'querystring';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user = {
    objectId: null,
    name: null,
    mobile: null,
    email: null,
    city: null,
    state: null,
    country: null,
    pin: null,
    id_proof: null,
    address_proof: null,
    bus_proof: null,
    user_type: null,
  };
  count = 0;
  limit = 100;

  listings = [];
  loading = true;
  error = false;

  search_query = null;
  search_filter = 'all';

  fileToUpload: File = null;
  idProofSrc = null;
  addressProofSrc = null;
  businessProofSrc = null;
  options = 'form';
  buyerView = "list";
  sellerView = "list";
  BidArray: any[] = [];
  bids: any[] = [];
  currentItem: any;
  currentBid: any;
  submittedOffers: any[] = [];
  currentSellerOffer = {
    offer: {
      list_id: null,
      bidder_id: null,
      o_qty: null,
      o_price: null,
      o_date_of_delivery: null,
      o_farm_address: null,
      o_comments: null,
      o_submitted_date: null,
      o_status: 'S'
    },
    listing: null,
    offerId: null

  };
  currentBidId: any = null;

  activeTab = "listings";

  constructor(
    private http: Http, 
    public system: SystemService, 
    private router: Router,
    private title: Title, 
    private meta: Meta, 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.activeTab = fragment;
      }
    });

    this.route.params.subscribe(params => {
      if (typeof params['tabId'] !== "undefined") {
        this.activeTab = params['tabId'];
      }
    });


    this.title.setTitle('My Profile');
    if (localStorage.getItem('user')) {

      const headers = new Headers();

      const userId = localStorage.getItem('user');

      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-Master-Key', environment.PARSE_MASTER_KEY);

      this.http.get(this.system.generateRequestUrl('/users/' + userId, {}, environment.serverURL), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          this.user = response;
          if (this.user.user_type === 'BB' || this.user.user_type === 'CF') {
            this.system.IS_PREMIUM = true;
          }
        });

      Parse.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
      (Parse as any).serverURL = environment.serverURL;

      const listing = Parse.Object.extend('ff_newlisting');
      const query = new Parse.Query(listing);

      if (this.search_filter === 'buy') {
        query.equalTo('list_type', 'B');
      } else if (this.search_filter === 'sell') {
        query.equalTo('list_type', 'S');
      } else if (this.search_filter === 'tender') {
        query.equalTo('list_type', 'T');
      }

      if (this.search_query) {
        query.matches('list_title', this.search_query, 'i');
      }

      query.equalTo('listedBy', new Parse.Object('_User', { 'objectId': localStorage.getItem('user') }));

      query.descending('createdAt');
      query.find().then((results) => {
        this.listings = results;
        this.loading = false;
        this.error = false;
      }, (error) => {
        this.loading = false;
        this.error = true;

        console.log(error);
      });
    }
  }

  saveUser() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
    headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);

    this.http.post(this.system.generateRequestUrl('/functions/UserUpdate', null, environment.serverURL),
      JSON.stringify({
        user_id: localStorage.getItem('user'),
        email: this.user.email,
        mobile: this.user.mobile,
        city: this.user.city,
        state: this.user.state,
        country: this.user.country,
        pin: this.user.pin,
        id_proof: this.user.id_proof,
        address_proof: this.user.address_proof,
        bus_proof: this.user.bus_proof
      }), { headers: headers })
      .map(res => res.json()).subscribe(response => {
        alert('Changes Saved');
      }, error => {
        alert('Error: Something went wrong, please try again later.');
      });
  }

  handleFileInput(files: FileList, proof) {
    this.fileToUpload = files.item(0);
    const file = files[0];

    const reader = new FileReader();

    if (proof === 'id') {
      reader.onload = e => this.idProofSrc = reader.result;
    } else if (proof === 'address') {
      reader.onload = e => this.addressProofSrc = reader.result;
    } else if (proof === 'business') {
      reader.onload = e => this.businessProofSrc = reader.result;
    }



    // reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      const headers = new Headers();
      headers.append('X-Parse-Application-Id', environment.PARSE_APP_ID);
      headers.append('X-Parse-REST-API-Key', environment.PARSE_REST_API_KEY);
      headers.append('Content-Type', this.fileToUpload.type);

      this.http.post(this.system.generateRequestUrl('/functions/UserProof', null, environment.serverURL),
        JSON.stringify({
          user_id: localStorage.getItem('user'),
          type: proof,
          file_name: this.fileToUpload.name,
          file_data: (reader.result as string).split(',')[1],
          file_type: this.fileToUpload.type,
        }), { headers: headers })
        .map(res => res.json()).subscribe(response => {
          alert('Changes Saved');
        }, error => {
          alert('Error: Something went wrong, please try again later.');
        });
    };
    reader.readAsDataURL(file);
  }

}
