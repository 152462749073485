import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProductComponent } from './pages/product/product.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermPolicyComponent } from './pages/term-policy/term-policy.component';
import { RefundPolicyComponent } from './pages/refund-policy/refund-policy.component';
import { AboutComponent } from './pages/about/about.component';
import { SigninComponent } from './account/signin/signin.component';
import { SignupComponent } from './account/signup/signup.component';
import { ProfileComponent } from './account/profile/profile.component';
import { ProcessComponent } from './account/oauth/process/process.component';
import { ProductCreateComponent } from './pages/product-create/product-create.component';
import { SignoutComponent } from './account/signout/signout.component';
import { SubscriptionComponent } from './account/subscription/subscription.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { environment } from '../environments/environment';
import { TimeAgoPipe } from './system/time-ago.pipe';
// import { ErHandler } from './system/er-handler';

import { NgxJsonLdModule } from 'ngx-json-ld';
import { ProductListOldComponent } from './pages/product-list-old/product-list-old.component';
import { TenderDashboardComponent } from './pages/tender-dashboard/tender-dashboard.component';
import { OfferFormComponent } from './pages/offer-form/offer-form.component';
import { DeleteComponent } from './account/delete/delete.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PricingComponent,
    ContactComponent,
    ProductComponent,
    PrivacyPolicyComponent,
    TermPolicyComponent,
    RefundPolicyComponent,
    AboutComponent,
    SigninComponent,
    SignupComponent,
    ProfileComponent,
    ProcessComponent,
    ProductCreateComponent,
    SignoutComponent,
    SubscriptionComponent,
    SitemapComponent,
    ProductListComponent,
    TimeAgoPipe,
    ProductListOldComponent,
    TenderDashboardComponent,
    OfferFormComponent,
    DeleteComponent
  ],
  imports: [
    CommonModule,
    HttpModule,
    NgtUniversalModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    NgxJsonLdModule,
    InfiniteScrollModule,
    AngularFireModule.initializeApp(environment.FIREBASE),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
  ],
  providers: [
    // ErHandler,
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    // { provide: ErrorHandler, useClass: ErHandler }
  ],
})
export class AppModule { }

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}
