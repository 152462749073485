import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SystemService } from './system.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public router: Router, public system: SystemService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (!this.system.IS_LOGIN) {
        const stateUrl = state.url;
        if (stateUrl !== '/' && stateUrl !== '/home') {
          this.router.navigate(['account/signin'], { queryParams: { next: state.url } });
        } else {
          this.router.navigate(['account/signin']);
        }
        return false;
      }
      return true;
  }
}
