import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-term-policy',
  templateUrl: './term-policy.component.html',
  styleUrls: ['./term-policy.component.scss']
})
export class TermPolicyComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle('Terms and Conditions');
  }

}
