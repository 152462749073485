import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { SystemService } from '../../system/system.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  env_url = 'null';
  constructor(
    public system: SystemService,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) { 
    this.env_url = environment.APP_URL;
  }

  ngOnInit() {
    this.title.setTitle('Signout...');
    console.error('environment.APP_URL', '.' + environment.APP_URL);

    this.signout();
  }

  async signout() {
    console.log('environment.APP_URL', '.' + environment.APP_URL);

    localStorage.removeItem('user');
    this.system.IS_LOGIN = false;
  }

}
