import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  IS_LOGIN = false;
  IS_PREMIUM = false;
  USER = {};

  

  constructor() { }

  generateRequestUrl(path, params, url) {

    if (typeof (path) === 'undefined' || path === null) {
      path = '/';
    }

    if (typeof (params) === 'object') {
      params = this.encodeUri(params);
    }

    if (typeof (params) === 'undefined') {
      return url + path;
    } else {
      return url + path + '?' + params;
    }

  }

  encodeUri(data, name?: string) {
    data = JSON.parse(JSON.stringify(data));
    const out = [];

    for (const key in data) {
      if (data[key] !== null && data[key].constructor === Array) {
        out.push(this.__http_build_query(data[key], key));
      } else if (data[key] !== null && typeof data[key] === 'object') {
        out.push(this.__http_build_query(data[key], key));
      } else {
        out.push(key + '=' + data[key]);
      }
    }
    return out.join('&');
  }

  seo_url_generate(type, title, location, extension = '.html', seperator = '-') {
    let str = '';
    if (type === 's') {
      str += 'sell' + seperator;
    } else if (type === 'b') {
      str += 'buy' + seperator;
    }

    str += title.replace(/[^a-zA-Z0-9]/g, seperator);
    if (location) {
      str += seperator + 'in' + seperator + location.replace(/[^a-zA-Z0-9]/g, seperator);
    }
    if (extension) {
      str += extension;
    }
    return str;
  }

  seo_keyword_generate(string) {
    return string.replace(/[^a-zA-Z]/g, ',');
  }

  __http_build_query(arr, name) {
    const out = [];
    for (const key in arr) {
      if (arr[key] !== null && arr[key].constructor === Array) {
        out.push(this.__http_build_query(arr[key], name + '[' + key + ']'));
      } else if (arr[key] !== null && typeof arr[key] === 'object') {
        out.push(this.__http_build_query(arr[key], name + '[' + key + ']'));
      } else {
        out.push(name + '[' + key + ']=' + arr[key]);
      }
    }
    return out.join('&');
  }

  dayOfYear() {
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    const oneDay = 1000 * 60 * 60 * 24;

    return Math.floor(diff / oneDay);
  }
}
